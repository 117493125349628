import provider from "./config";

export async function login(payload) {
  try {
    const response = await provider.post("authentication/", payload);
    return response.data;
  } catch (err) {
    if(err.response && err.response.data && err.response.data.error){
      throw Error(err.response.data.error);
    }
    if (err.response && err.response.data && err.response.data.non_field_errors.length) {
      throw Error(err.response.data.non_field_errors[0]);
    }

    throw Error("Erro ao logar");
  }
}

export async function requestResetPassword(payload) {
  try {
    const response = await provider.post("password-reset/", payload);
    return response.data;
  } catch (err) {
    throw Error("E-mail inválido");
  }
}

export async function validateResetPasswordToken(payload) {
  try {
    const response = await provider.post(
      "password-reset/validate_token/",
      payload
    );
    return response.data;
  } catch (err) {
    throw Error("Token inválido");
  }
}

export async function resetPassword(payload) {
  try {
    const response = await provider.post("password-reset/confirm/", payload);
    return response.data;
  } catch (err) {
    if(err.response.data.password){
      throw Error(err.response.data.password[0]);
    }
    throw Error(err.message);
  }
}
