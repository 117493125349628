import React from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import { useAuth } from "./contexts/auth";
import "react-dual-listbox/lib/react-dual-listbox.css";
import "./scss/style.scss";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

function AccessRoute({ type, Component, children, ...rest }) {
  const { isAuthenticated } = useAuth();

  function getAccess(props) {
    const redirectTo = {
      pathname: type === "auth" ? "/" : "/login",
      state: { from: props.location },
    };

    if (type === "auth") {
      if (!isAuthenticated) return <Component {...props} />;
      return <Redirect to={redirectTo} />;
    }

    if (isAuthenticated) return <Component {...props} />;
    return <Redirect to={redirectTo} />;
  }

  return <Route {...rest} render={(props) => getAccess(props)} />;
}

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const RequestResetPassword = React.lazy(() =>
  import("./views/pages/RequestResetPassword")
);
const ResetPassword = React.lazy(() => import("./views/pages/ResetPassword"));

function App() {
  return (
    <HashRouter>
      <React.Suspense fallback={loading}>
        <Switch>
          <AccessRoute
            exact
            type="auth"
            path="/login"
            name="Login"
            Component={Login}
          />
          <AccessRoute
            exact
            type="auth"
            path="/esqueceu-a-senha"
            name="RequestResetPassword"
            Component={RequestResetPassword}
          />
          <AccessRoute
            exact
            type="auth"
            path="/recuperar-senha"
            name="ResetPassword"
            Component={ResetPassword}
          />
          <AccessRoute path="/" name="Home" Component={TheLayout} />
        </Switch>
      </React.Suspense>
    </HashRouter>
  );
}

export default App;
