/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useState, useEffect } from "react";
import { addToast } from "src/components/Notification";
import * as authService from "../providers/auth";
import { userService } from "../providers/user";

const AuthContext = createContext({
  isAuthenticated: null,
  user: null,
  signIn: null,
  signOut: null,
  update: null,
  requestResetPassword: null,
  resetPassword: null,
  updateContactMessages: null,
  setUpdateContactMessages: null,
});

let permissionUpdateInterval;

function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [updateContactMessages, setUpdateContactMessages] = useState(false);

  useEffect(() => {
    retrieveUser();
  }, []);

  useEffect(() => {
    if(!user) return;
    startPermissionUpdateInterval();
  }, [user]);

  function saveUser(user) {
    setUser(user);
    localStorage.setItem("@mutase-admin", JSON.stringify(user));
  }

  async function retrieveUser() {
    const localUser = localStorage.getItem("@mutase-admin");

    if (localUser) {
      const userJson = JSON.parse(localUser);
      const response = await userService.get(userJson.id, userJson.token);
      if (response) {
        const { deactivate_login } = response;

        if(!deactivate_login) {
          return setUser(userJson);
        }
        localStorage.clear();
        setUser(null);
        addToast("error", "Erro", "Usuário desativado");
      }
    }
  }

  function startPermissionUpdateInterval(){
    clearInterval(permissionUpdateInterval)
    permissionUpdateInterval = setInterval(() => {
      getPermissions();
      if(user.user_type.name === "DIRETOR")
        setUpdateContactMessages(true);
    }, 60000);
  }

  async function getPermissions(){
    if(!user) return;
    try {
      const result = await userService.get(user.id, user.token);
      saveUser({...user, user_type: {...user.user_type, permissions: result.user_type.permissions}});
    } catch (err) {
      addToast("error", "Erro", err.message);
    }
  }

  async function signIn(payload) {
    try {
      const result = await authService.login(payload);
      startPermissionUpdateInterval();
      return saveUser({ token: result.token, ...result.user });
    } catch (err) {
      addToast("error", "Erro", err.message);
    }
  }

  function signOut() {
    clearInterval(permissionUpdateInterval);
    setUser(null);
    return localStorage.clear();
  }

  async function update(payload) {
    try {
      const result = await userService.update(user.id, payload, user.token);
      saveUser({ token: user.token, ...result });
      return true;
    } catch (err) {
      throw Error(err);
    }
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: !!user,
        user,
        signIn,
        signOut,
        update,
        updateContactMessages,
        setUpdateContactMessages
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);

  return context;
}

export default AuthProvider;
