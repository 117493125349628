import provider from "./config";

class Provider {
  constructor(key, endpoint) {
    this.endpoint = endpoint;
    this.key = key;
    this.provider = provider;
  }

  async list(filters, token) {
    let queryParams = filters ? filters : {};
    let url = this.endpoint;
    if (!this.endpoint.includes('/')) {
      url += '/';
    }

    try {
      const response = await this.provider.get(url, {
        params: queryParams,
        headers: {
          Authorization: `Token ${token}`,
        },
      });

      return response.data;
    } catch (err) {
      throw Error(`Ocorreu um erro ao tentar listar ${this.key}`);
    }
  }

  async get(id, token) {
    try {
      const response = await this.provider.get(`${this.endpoint}/${id}/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });

      return response.data;
    } catch (err) {
      throw Error(`Ocorreu um erro ao tentar buscar ${this.key}`);
    }
  }

  async create(payload, token) {
    try {
      const response = await this.provider.post(`${this.endpoint}/`, payload, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });

      return response.data;
    } catch (err) {
      if (err.response && err.response.data && err.response.data.length > 0) {
        if (err.response.data[0] === "A trainee was found with same taxpayer") {
          throw Error("A trainee was found with same taxpayer")
        } else if (err.response.data[0] === "CPF já cadastrado") {
          throw Error("CPF já cadastrado")
        }
      }
      if(err.response && err.response.data) {
        try {
          const keys = Object.keys(err.response.data);
          for(const k of keys) {
            if(err.response.data[k][0]) {
              throw Error(err.response.data[k][0])
            }
          }
        } catch (error) {
          throw Error(error.message);
        }
      }

      throw Error(`Ocorreu um erro ao tentar criar ${this.key}`);
    }
  }

  async update(id, payload, token) {
    try {
      const response = await this.provider.patch(
        `${this.endpoint}/${id}/`,
        payload,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      if (err.response && err.response.data && err.response.data.length > 0) {
        if (err.response.data[0] === "CPF já cadastrado") {
          throw Error("CPF já cadastrado")
        }
      }
      throw Error(`Ocorreu um erro ao tentar atualizar ${this.key}`);
    }
  }

  async delete(id, token) {
    try {
      await this.provider.delete(`${this.endpoint}/${id}/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
    } catch (err) {
      throw Error(`Ocorreu um erro ao tentar apagar ${this.key}`);
    }
  }
}

export default Provider;
